@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

div.w-md-editor.w-md-editor-show-live {
    overflow: hidden !important;

    ul {
        @apply mb-4;
        @apply mt-0;
        @apply list-inside;
        @apply list-disc;
    }

    ol {
        @apply mb-4;
        @apply mt-0;
        @apply list-inside;
        @apply list-decimal;
    }
}

div.markdown {
    > :first-child {
        @apply mt-0;
    }

    > :last-child {
        @apply mb-0;
    }

    h1,
    h2 {
        @apply border-b;
        @apply border-gray-200;
        @apply pb-1;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-bold;
        @apply mt-6;
        @apply mb-4;
        @apply leading-5;
    }

    h1 {
        @apply text-2xl;
    }

    h2 {
        @apply text-xl;
    }

    h3 {
        @apply text-lg;
    }

    h4 {
        @apply text-base;
    }

    h5 {
        @apply text-sm;
    }

    h5 {
        @apply text-xs;
    }

    ol,
    ul {
        @apply pl-8;
    }

    ul {
        @apply mb-4;
        @apply mt-0;
        @apply list-inside;
        @apply list-disc;
    }

    ol {
        @apply mb-4;
        @apply mt-0;
        @apply list-inside;
        @apply list-decimal;
    }

    > blockquote,
    > blockquote blockquote {
        @apply m-0;
        @apply border-l-4;
        @apply border-gray-300;
        @apply text-gray-700;
        @apply px-0;
        @apply py-4;
        > :last-child {
            @apply m-0;
        }
        > :first-child {
            @apply m-0;
        }
    }

    hr {
        @apply m-5;
        @apply m-auto;
        @apply border-none;
        @apply border-t;
        @apply border-gray-300;
        height: 1px;
    }

    > table,
    > blockquote table {
        @apply block;
        @apply overflow-auto;
        @apply w-full;
        @apply border-collapse;
        @apply text-sm;
        border-spacing: 0;

        tr {
            @apply bg-white;
            @apply border-t;
            @apply border-gray-300;
        }
        td,
        th {
            @apply border;
            @apply border-gray-300;
            @apply py-4;
            @apply px-2;
        }
    }
    blockquote,
    details,
    dl,
    ol,
    p,
    pre,
    table,
    ul {
        @apply mb-4;
        @apply mt-0;
    }

    a {
        @apply text-teal-500;
        @apply no-underline;
        &:hover {
            @apply text-teal-700;
        }
    }
}

div.loading-text:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(5, end) 1000ms infinite;
    animation: ellipsis steps(5, end) 1000ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
}

@keyframes ellipsis {
    to {
        width: 1.5rem;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.5rem;
    }
}

// Import the vendor css for ag-grid
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

// Import the overrides for ag-grid
.ag-theme-alpine {
    @include ag-theme-alpine(
        (
            borders: false,
            alpine-active-color: #1b8e96,
        )
    );

    .ag-layout-auto-height .ag-center-cols-clipper {
        min-height: 50px;
    }

    .ag-row {
        border-bottom: 1px solid #edf2f7;
    }

    .ag-paging-panel {
        border: none;
    }

    .cell-wrap-text {
        white-space: normal !important;
    }
}

// Import the vendor css for tippyjs
@import "~tippy.js/dist/tippy.css";

// Import the vendor css for react-datepicker
@import "~react-datepicker/dist/react-datepicker.min.css";

// Import the overrides for the react-datepicker
@import "overrides/react-datepicker.scss";

// Import the overrides for the modal
@import "overrides/react-modal.scss";

.ag-cell-focus, .ag-cell {
    border:none !important;
  }