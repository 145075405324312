/**
* Define a default transition time
*/
$fade-in-duration: 0.2s;

.ReactModal__Html--open,
.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: fadein $fade-in-duration; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein $fade-in-duration; /* Firefox < 16 */
    -ms-animation: fadein $fade-in-duration; /* Internet Explorer */
    -o-animation: fadein $fade-in-duration; /* Opera < 12.1 */
    animation: fadein $fade-in-duration;
}

.ReactModal__Content {
    background: white;
    outline: none;
    width: 50rem;
    max-width: calc(100vw - 2rem);
    max-height: calc(500vh - 2rem);
    box-shadow: 0 0 34px 0 rgba(0, 0, 0, 0.24);
    overflow-y: auto;
    position: relative;
    -webkit-animation: fadein $fade-in-duration; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein $fade-in-duration; /* Firefox < 16 */
    -ms-animation: fadein $fade-in-duration; /* Internet Explorer */
    -o-animation: fadein $fade-in-duration; /* Opera < 12.1 */
    animation: fadein $fade-in-duration;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
